import React from "react"
import mailIcon from "./SWIcons/email.svg"
import fbIcon from "./SWIcons/fb.svg"
import twIcon from "./SWIcons/tw.svg"
import waIcon from "./SWIcons/wa.svg"
import "./index.scss"

export default function ShareWidget({ host, pathname, title }) {
  return (
    <div className="share-widget-wrapper">
      <div className="share-widget">
        <ul>
          <li>
            <a href={`mailto:?body=https%3A%2F%2Fwww.${host}${pathname}`}>
              <img src={mailIcon} alt="share over email" />
            </a>
          </li>
          <li>
            <a
              href={`https://www.facebook.com/share.php?u=https%3A%2F%2Fwww.${host}${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={fbIcon} alt="share over facebook" />
            </a>
          </li>
          <li>
            <a
              href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.${host}${pathname}&text=${title}&via=powerswitchact&lang=en`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twIcon} alt="share over twitter" />
            </a>
          </li>
          <li>
            <a
              href={`https://api.whatsapp.com/send?text==https%3A%2F%2Fwww.${host}${pathname}?text=${title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={waIcon} alt="share over twitter" />
            </a>
          </li>
        </ul>
        <p>Share</p>
      </div>
    </div>
  )
}
