import React from "react"
import Seo from "../components/seo"
import "../styles/pages/report.scss"
import { graphql, Link } from "gatsby"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import ShareWidget from "../components/ShareWidget"
import DynamicSlider from "../components/DynamicSlider"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ComponentSelector from "../components/ComponentSelector"

export default function BasicReport({ data, pageContext, location }) {
  const { slug, title, showFooterForm } = pageContext
  const { host, pathname } = location

  const {
    id,
    metadata,
    introText,
    downloads,
    datePosted,
    thematicTags,
    reportSection,
    featuredImage,
    reportSubtitle,
    typeOfResource,
    relatedProjects,
    relatedFocusAreas,
    pageBottomComponents,
    headerBackgroundImage,
  } = data.basicReport.nodes[0]

  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;

  const thematicTagsArray = thematicTags.split(", ")

  const reportTagsArray = [
    {
      tagLabel: "thematicTags",
      tags: [...thematicTagsArray],
    },
    {
      tagLabel: "relatedFocusAreas",
      tags: relatedFocusAreas.map(focusArea => focusArea.title),
    },
    {
      tagLabel: "relatedProjects",
      tags: relatedProjects.map(project => project.title),
    },
  ]

  // = = = = = = = = = = = = =
  // Sliders Props
  const resourcesSliderProps = {
    limit: 6,
    skip: id,
    cardColor: "#18988B",
    cardAlignment: "horizontal",
    headline: "<span>Related</span> Resources",
    link: [
      {
        label: "All resources",
        linkUrl: "/resources",
        isThisAButton: false,
      },
    ],
    relatedProjects: relatedProjects,
    relatedFocusAreas: relatedFocusAreas,
    contentType: [
      {
        contentType: "resource",
      },
    ],
    background: [
      {
        hasBackgroundImage: true,
      },
    ],
  }

  const pressSliderProps = {
    cardColor: "#1F1646",
    cardAlignment: "horizontal",
    headline: "In the news",
    relatedResources: [{title: title}],
    link: [],
    skip: id,
    contentType: [
      {
        contentType: "press",
      },
    ],
    background: [
      {
        hasBackgroundImage: false,
        backgroundColor: "#E5E4F3",
      },
    ],
  }

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />

        <div className="basic-report-page" >
          <div className={`basic-report-page-header hero ${headerBackgroundImage ? null : 'hero--black'}`}>
            <GatsbyImage
              image={getImage(headerBackgroundImage)}
              className="hero__bg"
            />
            <div className="container">
              <div className="row hero__breadcrumb">
                <div className="col justify-content-start">
                  <Link to="/resources" className="oblique text-white">
                    ‹ Resources
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <div className="hero--image">
                    <GatsbyImage
                      className="hero__image"
                      image={getImage(featuredImage)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="hero__text">
                    <span className="tag">{typeOfResource.replace("-", " ")}</span>
                    <h1 className="text-white oblique">{title}</h1>
                    <h5 className="oblique">{reportSubtitle}</h5>
                    <p className="text-white">{datePosted}</p>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>

          <div className="report-download-container">
            <div className="container">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <div className="download-box">
                  <div dangerouslySetInnerHTML={{__html: introText}}/>
                    {downloads.length > 0 &&
                      downloads.map(download => (
                        <a
                          className="btn btn-secondary"
                          download
                          href={download.pdf.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {download.buttonText}
                        </a>
                      ))}
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>

          <div className="report-page-content">
            <ShareWidget title={title} host={host} pathname={pathname} />
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <ComponentSelector components={reportSection} />
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>

          {reportTagsArray
            .map(tagsObject => tagsObject.tags)
            .flat()
            .toString().length > 0 && (
            <section className="update__tags">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="content-wrapper">
                      <h5 className="oblique">Tagged</h5>
                      <ul className="tags-list">
                        {reportTagsArray.map(tagObject =>
                          tagObject.tags.map(
                            tag =>
                              // Generate li just if tag has value
                              tag && (
                                <li className="update__tag" key={tag}>
                                  <Link
                                    to={`/resources?${
                                      tagObject.tagLabel
                                    }=${tag.replaceAll(" ", "_")}`}
                                  >
                                    {tag}
                                  </Link>
                                </li>
                              )
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>

        <ComponentSelector components={pageBottomComponents} />

        <DynamicSlider component={resourcesSliderProps} />
        <DynamicSlider component={pressSliderProps} />

        <Footer showFooterForm={showFooterForm} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    basicReport: allDatoCmsResource(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        typeOfResource
        thematicTags
        title
        introText
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        downloads {
          buttonText
          componentId
          pdf {
            url
          }
        }
        reportSubtitle
        datePosted(formatString: "MMMM YYYY")
        headerBackgroundImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        slug
        showFooterForm
        featuredImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        relatedFocusAreas {
          title
        }
        relatedProjects {
          title
        }
        thematicTags
        reportSection {
          ... on DatoCmsSocialMediaEmbed {
            smEmbed
            componentId
          }
          ... on DatoCmsVideoEmbed {
            componentId
            embedCode
            youtubeOrVimeo
            imageOverlay
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsTextContent {
            content
            componentId
          }
        }
        pageBottomComponents {
          ... on DatoCmsResourcePageCta {
            componentId
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            headline
            subtitleText
            fullWidth
            button {
              linkUrl
              label
            }
          }
        }
      }
    }
  }
`
